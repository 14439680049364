<template>
  <v-container class="bg-glass-effect" fluid>
    <BackArrow/>

    <v-row class="pt-14">
      <v-col cols="12">
        <v-sheet class="mx-3" rounded="xl" min-height="300">
          <v-row>
            <v-col class="col-medical-mask-picture" offset="8" cols="4">
              <v-img class="medical-mask-picture" :src="require('../../../public/img/knowledgeTrail/medical-mask-knowledgeTrail.svg')"></v-img>
            </v-col>
            <v-col class="px-10" cols="12">
              <h1 class="content-title">Trilha de conhecimento</h1>
            </v-col>
            <v-col class="px-10 py-0 pb-12" cols="12">
              <p class="content-description">Teste seu conhecimento sobre o Coronavírus</p>
            </v-col>
            <v-col class="px-10 pt-12 pb-6" cols="12">
              <GenericBtn
                  color="tertiary"
                  class="btn-start"
                  :btn-props="{
                    block: true,
                    large: true,
                  }"
                  :on-click="() => (redirectToSurvey())"
              >Começar</GenericBtn>
            </v-col>
          </v-row>

        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BackArrow from '@/components/covid/back-arrow/index'
  import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";
  import {mapMutations} from "vuex";
  import GenericBtn from "../../components/generic-btn/index"

  export default {
    name: "knowledgeTrail",
    data: () => ({
      redirect: {
        name: 'survey',
        query: {
          id: '6'
        }
      }
    }),
    components: {
      BackArrow,
      GenericBtn,
    },
    beforeMount() {
      this._euProtegidoService = new EuProtegidoService()
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      async redirectToSurvey () {
        this.loading(true)
        await this._euProtegidoService.GetKnowledgeTrailSurveyIdByFinancialGroup()
            .then(response => {
              this.redirect.query.id = response.data
              this.$router.push(this.redirect)
            }).catch(() => {
              this.showmsg({ text: "Erro ao carregar os dados para redirecionamento da Trilha.", type: "error" });
            })
        this.loading(false)
      }
    }
  }
</script>

<style scoped>
  /*background effect blur*/
  .bg-glass-effect{
    background: rgba(255,255,255,.8) !important;
    mix-blend-mode: normal !important;
    backdrop-filter: blur(100px) !important;
    height: 100%;
  }

  .col-medical-mask-picture{
    width: 225px;
    height: 140px;
    overflow: visible;
  }

  .medical-mask-picture {
    position: absolute;
    width: 225px;
    height: 180px;
    top: 20px;
    right: -5px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .content-title {
    font-family: Open Sans;
    font-style: normal;
    font-size: 36px;
    font-weight: bold;
    line-height: 120%;
    color: #4A499B;
  }

  @media (max-width: 320px) {
    .content-title{ font-size: 34px; }
  }

  @media (min-width: 430px) {
    .content-title{ font-size: 38px; }
  }

  @media (min-width: 470px) {
    .content-title{ font-size: 36px; }
  }

  .content-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #868598;
  }

  .btn-start{
    text-transform: capitalize !important;
    border-radius: 20px !important;
    color: white !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
  }
</style>


